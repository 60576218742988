:root {
  --primary-color: #18c687;
  --primary-text-color: #fff;
  --background-color: #f4f4f4;
  --text-color: #333;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow-y: auto;
}

.login-card {
  display: flex;
  flex-direction: row;
  width: 800px;
  height: 500px;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  .left-section {
    flex: 1;
    background-color: var(--primary-color);
    border-radius: 12px 0 0 12px;
    color: var(--primary-text-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    h4 {
      margin-bottom: 20px;
    }

    .logo {
      width: 150px;
      margin-bottom: 20px;
    }
  }

  .right-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border: 2px solid var(--primary-color);
    background-color: var(--background-color);
    border-radius: 0 12px 12px 0;

    form {
      width: 100%;

      .input-field {
        margin-bottom: 20px;

        input {
          color: var(--text-color);
        }

        label {
          color: var(--text-color);
        }
      }
      .sign-in-button {
        background-color: var(--primary-color);
        margin-bottom: 20px;
      }
    }
  }
}

/* Responsive styles */

/* Extra Small Devices (Phones) */
@media only screen and (max-width: 575px) {
  .login-card {
    flex-direction: column;
    width: 100%;
    height: auto;
    border-radius: 12px;

    .left-section {
      border-radius: 12px 12px 0 0;
      padding: 10px;
    }

    .right-section {
      border-radius: 0 0 12px 12px;
      padding: 10px;
    }
  }
}

/* Small Devices (Tablets - Portrait) */
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .login-card {
    flex-direction: column;
    width: 90%;
    height: auto;
    border-radius: 12px;

    .left-section {
      border-radius: 12px 12px 0 0;
      padding: 15px;
    }

    .right-section {
      border-radius: 0 0 12px 12px;
      padding: 15px;
    }
  }
}

/* Medium Devices (Tablets - Landscape) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-card {
    flex-direction: column;
    width: 80%;
    height: auto;
    border-radius: 12px;

    .left-section {
      border-radius: 12px 12px 0 0;
      padding: 20px;
    }

    .right-section {
      border-radius: 0 0 12px 12px;
      padding: 20px;
    }
  }
}

/* Large Devices (Desktops - Small to Medium) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .login-card {
    flex-direction: row;
    width: 700px;
    height: 450px;
    border-radius: 12px;

    .left-section {
      padding: 20px;
    }

    .right-section {
      padding: 20px;
    }
  }
}

/* Extra Large Devices (Desktops - Large) */
@media only screen and (min-width: 1200px) {
  .login-card {
    flex-direction: row;
    width: 800px;
    height: 500px;
    border-radius: 12px;

    .left-section {
      padding: 20px;
    }

    .right-section {
      padding: 20px;
    }
  }
}
