/* UserWidget.css */
@media (max-width: 600px) {
  .card-container {
    width: 530% !important;

    .title {
      font-size: 44px !important;
    }

    .totalUsers {
      font-size: 36px !important;
    }
  }
}
