/* Define styles for the container rows */
.row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.widget-container,
.chart-container,
.transactions-container,
.balance-widget-container {
  flex: 1 1 100%;
  margin-bottom: 20px;
}

/* Styles for small screens */
@media (max-width: 767px) {
  
  .row {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .widget-container,
  .chart-container,
  .transactions-container,
  .balance-widget-container {
    /* Ensure each item takes full width */
    width: 100%;
    font-size: 1.2em; /* Example font size for container */

    /* Specific styles for nested elements */
    .widget-content,
    .chart-content,
    .transactions-content,
    .balance-content {
      font-size: 1.2em; /* Increase font size for content */
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span {
      font-size: 1.2em; /* Increase font size for headers and text */
    }
  }
}

/* Styles for medium screens */
@media (min-width: 768px) and (max-width: 991px) {
  .widget-container,
  .chart-container,
  .transactions-container,
  .balance-widget-container {
    flex: 1 1 100%; /* Ensure one per row on medium screens */
  }
}

/* Styles for large screens */
@media (min-width: 992px) {
  .widget-container {
    flex: 1 1 25%;
  }

  .chart-container {
    flex: 1 1 50%;
  }

  .transactions-container {
    flex: 1 1 66.67%;
  }

  .balance-widget-container {
    flex: 1 1 33.33%;
  }
}
