.featured {
  flex: 2;
  box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);

  /* featured.scss */
  .featured canvas {
    width: 100% !important;
    height: auto !important;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: gray;
  }


}
